/* eslint-disable @next/next/no-img-element */

import styled from 'styled-components';

const HiddenPixelCapterra = styled.img`
  position: absolute;
  visibility: hidden;
  pointer-events: none;
`;

export const CapterraPixel = () => {
  return (
    <HiddenPixelCapterra
      src="https://ct.capterra.com/capterra_tracker.gif?vid=2200116&vkey=4348ec076f6af11302f569a500e532e9"
      height="0"
      width="0"
      alt=""
    />
  );
};
